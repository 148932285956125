// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

@use "sass:map";
@use "sass:math";

#site-header {
  .navbar-nav .nav-link {
    text-transform: lowercase;
  }

  .navbar-nav .navbar-nav .nav-link {
    text-transform: none;
  }

  // On large enough screens the header moves to the side of the page and is fixed to the top of the container gutter
  @include media-breakpoint-up(xxl) {
    position: fixed;
    top: $section-spacing-lg + 3rem; // 3rem is the height of the red bar at the top of the page
    right: calc(50% + #{map.get($container-max-widths, "xxl") * 0.5});
    width: 180px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border: none;
    box-shadow: none;
    transition: none;

    .navbar-brand img {
      height: math.div(45, 17) * 1rem;
    }

    .navbar-nav {
      flex-direction: column;
    }

    .nav-link {
      position: relative;
      left: -0.5em;
    }
  }

  // Adjust the header position as the container size changes
  @include media-breakpoint-up(xxxl) {
    right: calc(50% + #{map.get($container-max-widths, "xxxl") * 0.5});
  }
}
