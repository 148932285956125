.scroll-carousel {
  position: relative;

  &::after,
  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    position: absolute;
    top: 0;
    height: 100%;
    width: 3rem;
    background: linear-gradient(to right, rgb(0 0 0 / 0.01%) 0, rgb(0 0 0 / 25%) 100%);
    z-index: 1;
    pointer-events: none;
    transition: opacity 500ms;
  }

  &::before {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-compact-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223"/></svg>');
    left: 0;
    background: linear-gradient(to left, rgb(0 0 0 / 0.01%) 0, rgb(0 0 0 / 25%) 100%);
  }

  &::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-chevron-compact-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"/></svg>');
    right: 0;
    background: linear-gradient(to right, rgb(0 0 0 / 0.01%) 0, rgb(0 0 0 / 25%) 100%);
  }

  &:has([data-scroll-percentage-x="0"])::before {
    opacity: 0;
  }

  &:has([data-scroll-percentage-x="100"])::after {
    opacity: 0;
  }

  @include media-breakpoint-up(md) {
    &::before,
    &::after {
      display: none;
    }
  }
}
