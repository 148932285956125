// Size the banner text on each page to fit the text exactly at each container breakpoint size
.page-banner-text {
  @include media-breakpoint-up(md) {
    // This default width for the `info` pages is enough to display the banner CTA text in two equal lines. This width
    // is increased on a page-by-page basis in order to make sure the h1 heading fits on a single line
    [class^="page-info-"] &,
    [class*=" page-info-"] & {
      width: 575px;
    }

    // prettier-ignore
    .page-home & { width: 495px; } // prettier-ignore
    .page-info-solvent-extraction-equipment & { width: 630px; } // prettier-ignore
    .page-info-process-optimization & { width: 600px; } // prettier-ignore
    .page-info-custom-fluid-equipment-for-cmos & { width: 625px; } // prettier-ignore
    .page-info-hazardous-location-compliance & { width: 585px; } // prettier-ignore
    .page-info-pharmaceutical-and-biopharmaceutical & { width: 685px; } // prettier-ignore
  }

  @include media-breakpoint-up(lg) {
    // prettier-ignore
    .page-home & { width: 535px; } // prettier-ignore
    .page-info-stainless-steel-reaction-vessels & { width: 610px; } // prettier-ignore
    .page-info-chemical-process-engineering & { width: 605px; } // prettier-ignore
    .page-info-start-up-and-pilot-plant-solutions & { width: 610px; } // prettier-ignore
    .page-info-custom-fluid-equipment-for-cmos & { width: 665px; } // prettier-ignore
    .page-info-hazardous-location-compliance & { width: 625px; } // prettier-ignore
    .page-info-pharmaceutical-and-biopharmaceutical & { width: 735px; } // prettier-ignore
  }
}
