// Choose a specific image alignment for certain page banners {
.page-banner-background img {
  .page-products-pressurized-equipment & {
    @media (orientation: landscape) {
      object-position: center 43%;
    }
  }

  .page-products-sanitary-equipment & {
    @media (orientation: landscape) {
      object-position: center 14%;
    }
  }
}
