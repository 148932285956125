.not-found-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center;

  h1 {
    font-size: 4em;
    line-height: 1;
    letter-spacing: -1px;
  }
}

@include media-breakpoint-up(xxl) {
  .not-found-container {
    max-width: 30%;
  }
}
