// ---------------------------------------------------------
// This file contains all styles related to layout for pages
// ---------------------------------------------------------

@use "sass:map";
@use "sass:math";

.page-section {
  @include section-spacing(margin-bottom);
}

.section-lead {
  @include media-breakpoint-up(lg) {
    // NOTE: Bottom margin should match h1 bottom margin
    margin-bottom: math.div(40, 17) * 1rem;
  }
}
