@use "sass:map";

// Define responsive utilities for bootstrap ratios to allow changing the aspect ratio of an element based on the screen orientation
@each $name, $ratio in $aspect-ratios {
  @media (orientation: landscape) {
    .ratio-landscape-#{$name} {
      --bs-aspect-ratio: #{$ratio};
    }
  }
}
