@use "sass:map";
@use "sass:math";

.page-banner {
  position: relative;

  // On large enough screens (i.e. when the text the floating),
  // Set the banner height to *at least* 80% of the screen height (include 110px as the header height)
  // NOTE: The banner will take up more space than this if the content is longer
  @include media-breakpoint-up(md) {
    min-height: calc((var(--vh) * 80) - 110px);
  }

  // When a ratio is specified for a banner, it is not sized according to its content
  // In this case, specify a max height, which will override the aspect ratio preference, and do not specify a min height
  &.ratio {
    max-height: calc((var(--vh) * 80) - 110px);
    min-height: auto;
  }
}

.page-banner-background {
  // <img> element always fills the background container (<picture> element), but maintains its own aspect ratio (will be cropped)
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  // On small screens, use a 16:9 aspect ratio for the image
  // The image is statically positioned and the text will be placed below it
  // However, do not enforce a specific aspect ratio if it is already enforced at the page-banner level
  @include media-breakpoint-down(md) {
    .page-banner:not(.ratio) & {
      padding-top: 56.25%;
      position: relative;

      > img {
        @include fill-relative-parent;
      }
    }
  }

  // On larger screens (i.e. when the text is floating) the background container (<picture> element) is positioned
  // absolutely and fills the banner container
  @include media-breakpoint-up(md) {
    @include fill-relative-parent;
  }
}

.page-banner-top-lines {
  // On small screens, the lines are absolutely positioned over top of the image
  position: absolute;
  top: 0;
  width: 100%;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
  z-index: 1;

  img {
    @include rfs(130px, height);
  }

  // On larger screens when the text is floating, the background image is absolute, and the lines/text are statically placed
  @include media-breakpoint-up(md) {
    position: static;
    padding-left: 2rem;
    padding-top: 2rem;
  }
}

.page-banner-text {
  z-index: 1;

  h1,
  .h1 {
    @include font-size(math.div(36, 16) * 1rem);

    font-weight: 700;
  }

  // On larger screens, position the text in the bottom left/right corner by constraining the width
  @include media-breakpoint-up(md) {
    margin: 2rem;
    border: none;
    box-shadow: var(--bs-box-shadow);

    &.page-banner-text-right {
      margin-left: auto;
    }

    p:not(.lead) {
      border-left: 2px solid $grey;
      padding-left: 0.5rem;
    }
  }
}

// Class is applied the element at the bottom of the banner on mobile screens (either the banner text, or the figure caption)
.page-banner-bottom-shadow {
  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid rgba($black, 0.12);
    box-shadow: var(--bs-box-shadow);
    margin-bottom: 0.5rem;
  }
}
