@use "sass:math";

.navbar {
  padding-top: math.div(12, 16) * 1rem;
  padding-bottom: math.div(12, 16) * 1rem;
}

.navbar.sticky-top {
  border-bottom: 1px solid $light;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 0%);
  transition:
    box-shadow 200ms,
    border-color 200ms;

  &.stuck-top {
    border-bottom: 1px solid $dark;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 40%);
  }
}

.navbar-nav {
  counter-reset: navbar-nav-counter;
  margin-top: math.div(12, 16) * 1rem;
}

.nav-item {
  &.active {
    font-weight: 700;
  }
}

.nav-link::before {
  counter-increment: navbar-nav-counter;
  content: "0" counter(navbar-nav-counter) "/";
  display: inline;
  margin-right: math.div(8, 16) * 1rem;
  font-size: math.div(8, 11) * 1em;
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
}

.nav-link > .bi-chevron-right {
  vertical-align: -0.16em;
  transition: transform 200ms;
}

.nav-link:has(+ .collapse.show, + .collapsing) > .bi-chevron-right {
  transform: rotate(90deg);
}

.navbar-nav .navbar-nav {
  margin-top: 0;
  list-style: disc;
  font-weight: normal;
  margin-left: 1em;

  .nav-link {
    font-size: 0.825em;
  }

  .nav-link::before {
    display: none;
  }

  @include media-breakpoint-up(xxl) {
    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
    }
  }
}
