// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
@use "sass:color";

.fill-relative-parent {
  @include fill-relative-parent;
}

.center-relative-parent {
  @include center-relative-parent;
}

@mixin section-spacing($property) {
  @include rfs($section-spacing-lg, #{$property});

  @include media-breakpoint-down(sm) {
    #{$property}: $section-spacing-xs;
  }
}

.section-margin-bottom {
  @include section-spacing(margin-bottom);
}

.section-margin-top {
  @include section-spacing(margin-top);
}

.section-padding-top {
  @include section-spacing(padding-top);
}

.section-padding-bottom {
  @include section-spacing(padding-bottom);
}

@mixin bg-white-blur {
  background-color: color.change($color: $white, $alpha: 60%);
  backdrop-filter: blur(7px) brightness(115%);
}

.bg-white-blur {
  @include bg-white-blur;
}

@each $breakpoint, $value in $grid-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .bg-white-blur-#{$breakpoint} {
      @include bg-white-blur;
    }
  }
}

$object-positions-x: center, left, right;
$object-positions-y: center, top, bottom;

@each $x in $object-positions-x {
  @each $y in $object-positions-y {
    .object-position-#{$x}-#{$y} {
      object-position: #{$x} #{$y} !important;
    }
  }
}
