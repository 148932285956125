.list-stylized {
  list-style: none;

  li {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }
}

ul.list-stylized {
  li::before {
    content: " ";
    position: absolute;
    left: $line-height-base * -1em;
    height: $line-height-base * 1em;
    width: $line-height-base * 1em;
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="%23ff391f"><path d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" fill-rule="evenodd"/></svg>');
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

ol.list-stylized {
  counter-reset: order-list-stylized;

  li::before {
    counter-increment: order-list-stylized;
    content: counter(order-list-stylized) "";
    position: absolute;
    left: $line-height-base * -1.4em;
    width: $line-height-base * 1em;
    height: $line-height-base * 1em;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $primary;
    border-radius: 50%;
  }
}
