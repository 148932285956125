.certification-images {
  img {
    height: 90px;
    width: auto;
    margin-right: 1em;
    margin-bottom: 1em;

    @include media-breakpoint-up(md) {
      height: 100px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
