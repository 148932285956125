@use "../vendor/bootstrap-variables";

.logo {
  // Use body color as default
  stroke: $body-color;

  // Inherit color from contextual color classes
  @each $color, $value in $theme-colors {
    .text-#{$color} & {
      stroke: $value;
    }
  }

  .stroke-normal,
  .stroke-highlight {
    fill: none;
    stroke-miterlimit: 10;
  }

  .stroke-normal {
    stroke-width: 10.9608;
  }

  .stroke-highlight {
    stroke-width: 11.1882;
  }
}
