.btn-primary {
  --bs-btn-color: #{$light};
  --bs-btn-hover-color: #{$light};
  --bs-btn-active-color: #{$light};
}

.btn-outline-primary {
  --bs-btn-bg: #{$light};
  --bs-btn-hover-color: #{$light};
  --bs-btn-active-color: #{$light};
}
