@use "sass:math";
@use "sass:color";

// Color System
$blue: #01a0e8;
$red: #ff391f;
$yellow: #ffd400;
$grey: #707070;
$black: #000;

// Re-assign the built-in named colors
// NOTE: $secondary and $success are left as their default values
$primary: $red;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: #fff;
$dark: #1c1c1c;
$off-white: #eaeaea;

// Select a subset of colors to use as the theme colors
$theme-colors: (
  "primary": $primary,
  "info": $blue,
  "warning": $yellow,
  "danger": $red,
  "dark": $dark,
  "light": $light,
  "grey": $grey,
  "off-white": $off-white,
);

// Options
// =======
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true;

// Body
// ====
// Settings for the `<body>` element.

$body-bg: $light;
$body-color: $dark;

// Links
// =====
// Style anchor elements.

$link-color: $body-color;
$link-hover-color: $red;

// Grid breakpoints
// ================
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  // Breakpoint when there is enough room for the side menu
  xxl: 1500px,
  // Breakpoint when we can increase the gutter padding to match the design
  xxxl: 1920px,
);

// Grid containers
// ===============
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // Use same container size as previous breakpoint, we just need the gutter to expand for the side menu
  xxl: 1141px,
  // Set this container to have minimum 250px gutters, to make room for the side menu
  xxxl: 1420px, // 1920px - 250px - 250px,
);

// Components
// ==========
// Define common padding and border radius sizes and more.

// Focus
$focus-ring-width: 0.1rem;

// Shadows
$box-shadow-sm: 0 0.25rem 0.5rem rgba($black, 0.12);

// Aspect Ratios
$aspect-ratios: (
  "1x1": 100%,
  "3x2": calc(2 / 3 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "14x5": calc(5 / 14 * 100%),
);

// Typography
// ==========
// Font, line-height, and color for body text, headings, and more.

$font-family-monospace: "Roboto Mono", monospace, sans-serif;
$font-family-base: "Montserrat", sans-serif;
$font-weight-normal: 500;

// Lead
$lead-font-size: 1.25rem;
$lead-font-weight: 600;

// hr
$hr-opacity: 1;

// Navbar

$navbar-light-color: $body-color;
$navbar-light-active-color: $body-color;

// Buttons
// =======
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-border-radius: 200px; // Use an arbitrary large value to make the buttons pill-shaped

// Forms
// =====

// Label
$form-label-margin-bottom: 0.3125rem;
$form-label-font-weight: 600;

// Input
$input-border-color: $grey;
$input-box-shadow: var(--bs-box-shadow-sm); // Default is to use an inset shadow

// Customize CSS variables
// See: https://getbootstrap.com/docs/5.3/customize/css-variables/
:root {
  --bs-emphasis-color: #{$red};
  --bs-emphasis-color-rgb: #{color.channel($red, "red"), color.channel($red, "green"), color.channel($red, "blue")};
}
