@use "sass:map";
@use "sass:math";

$accent-line-width: 6px;
$accent-angle: 30deg;
$accent-angle-length: 85px;

.accented-header-angled {
  @include section-spacing(margin-bottom);

  display: flex;
  flex-wrap: nowrap;

  @include media-breakpoint-down(md) {
    position: relative;
    padding-bottom: calc($accent-line-width + 0.5rem);
  }
}

.accented-header-angled-text {
  padding-right: 4rem;

  @include media-breakpoint-up(md) {
    padding-right: 0;
  }
}

.header-accent-angled {
  flex-grow: 1;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    position: absolute;
    inset: 0 -1 * math.div($grid-gutter-width, 2);
  }

  @include media-breakpoint-up(md) {
    // Set height to be half way down the second line (line height is 0.8), then add border width
    height: calc(0.8em * 1.5 + $accent-line-width);
    margin-left: 25px;

    // Also support the bootstrap line height utilities (since we might need to override the 0.8 line height for certain titles)
    @each $key, $value in map.get(map.get($utilities, "line-height"), "values") {
      .lh-#{$key} & {
        height: calc(#{$value}em * 1.5 + $accent-line-width);
      }
    }
  }
}

.header-accent-angled::before {
  content: "";
  display: inline-block;
  flex-grow: 1;
  border-bottom: 6px solid;
  border-color: inherit;
}

.header-accent-angled::after {
  content: "";
  display: inline-block;
  width: $accent-angle-length;
  border-bottom: $accent-line-width solid;
  border-color: inherit;
  border-radius: 3px;

  // Rotate the line from it's bottom left corner to form the angle
  // shift left one pixel to remove the gap between the two lines
  transform-origin: bottom left;
  transform: rotate(-#{$accent-angle}) translateX(-1px);

  // Rotating the line causes shortens it's overall horizontal length, but we can shift it back to the right
  // using a bit of trigonometry
  margin-right: -($accent-angle-length - ($accent-angle-length * cos($accent-angle))) - 1px;
}
