.project-logo {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto 1rem;
  object-fit: contain;
  object-position: bottom center;
}

// Square Logos
.yellowstone-logo,
.direct-distill-logo {
  width: 200px;
}

// Horizontal logos
.baromotive-logo,
.genome-logo {
  width: 300px;
}

// On larger screens we need to make sure all icons in each row have the same height
@include media-breakpoint-up(md) {
  .project-logo {
    width: auto;
  }

  // Square logos
  .yellowstone-logo,
  .direct-distill-logo {
    height: 200px;
  }

  // Horizontal logos
  .baromotive-logo,
  .genome-logo {
    height: 100px;
  }
}
