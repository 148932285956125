// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

// When automatically calculating the 'sizes' attribute with lazysizes, images needs to be laid out in a way that
// makes it possible to calculate their width
// See: https://github.com/aFarkas/lazysizes#automatically-setting-the-sizes-attribute
img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

// Hide images that have not yet been processed with lazysizes so that there is no flash of the broken image symbol
// See: https://github.com/aFarkas/lazysizes#broken-image-symbol
img.lazyload:not([src]) {
  visibility: hidden;
}

// Automatically add a "Figure # - " prefix to the caption of each figure
main {
  counter-reset: figure-counter;
}

figcaption::before {
  counter-increment: figure-counter;
  content: "Figure " counter(figure-counter) " - ";
}

figcaption.figure-above::before {
  content: "Figure " counter(figure-counter) " (above) - ";
}

// Utility class for sizing figure images within an article that are portrait-oriented
// These images have to be width constrained or they will take up the entire screen height
.article-figure-portrait {
  margin-left: auto;
  margin-right: auto;

  @include make-responsive-col(
    (
      sm: 10,
      md: 9,
      lg: 8,
      xl: 7,
      xxxl: 6,
    )
  );
}
