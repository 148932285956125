/**
 * Basic typography style for copy text
 */
@use "sass:math";

h1,
.h1 {
  @include font-size(math.div(40, 16) * 1rem);

  margin-bottom: 1.5rem;
  font-weight: 600;
  text-transform: lowercase;
  line-height: 0.8;
  position: relative;
}

h2,
.h2 {
  @include font-size(math.div(22, 16) * 1rem);

  font-weight: 700;
}

h3,
.h3 {
  @include font-size(math.div(20, 16) * 1rem);

  font-weight: 700;
  text-transform: lowercase;
}

.text-transform-none {
  text-transform: none !important;
}
