// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

@use "sass:math";

#site-footer {
  @include font-size(math.div(14, 16) * 1rem);

  .footer-copyright {
    font-size: 1rem;
  }

  hr {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
