// ----------------------------------------------------------------
// This file contains all styles related to the grid layout system
// ----------------------------------------------------------------

// Utility class for article copy text centered on the page
// Equivalent to col-12 col-lg-9 col-xl-8 col-xxxl-7
.col-article-copy {
  @include make-responsive-col(
    (
      xs: 12,
      lg: 9,
      xl: 8,
      xxxl: 7,
    )
  );
}
