// -----------------------------------------------------------------------------
// This file contains all custom properties to apply to the :root pseudo-element
// -----------------------------------------------------------------------------

:root {
  // Define viewport height as a fixed value for mobile devices where the viewport height changes when scrolling and
  // the address bar is hidden. These are default values, with the actual values set via JS on page load and resize
  --vh: 1vh;

  #{'--100vh'}: 100vh;
}
